import { Notification } from "../../common/Atoms/Notification";

import { NotFound } from "./NotFound";

export function ErrorMessage({ error }) {
  const errorMessage = error.message;

  if (error.originalStatus === 404) {
    return <NotFound />;
  }

  return (
    <div className={ `h-screen flex flex-col justify-center items-center` }>
      <Notification
        type={ `error` }
      >
        { errorMessage }
      </Notification>
    </div>
  );
}
