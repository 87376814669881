import { useState } from "react";

import { useApiRequest } from "../../main/hooks/useApiRequest";

// Download a file from server
export function useDownload() {
  const request = useApiRequest();
  const [downloading, setDownloading] = useState(false);

  async function download(url: string, filename: string, params?: Record<string, string | number | boolean>) {
    setDownloading(true);
    try {
      const res = await request({
        method: `GET`,
        responseType: `blob`,
        url,
        params,
      });

      const blobUrl = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement(`a`);
      link.href = blobUrl;
      link.setAttribute(`download`, filename);
      document.body.appendChild(link);
      link.click();
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setDownloading(false);
    }
  }

  return { download, downloading };
}
