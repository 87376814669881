import { useParams } from "react-router";

import { useFetchQuoteQuery } from "../services/api/quote";

import { useSession } from "./useSession";

export function usePortalQuote() {
  const session = useSession();

  const { code } = useParams<{ code: string}>();
  // On the second request we want to sync with accounting system
  // const [resync, setResync] = useState(false);
  const api = useFetchQuoteQuery({ code, sessionId: session?.id }, {
    // refetchOnMountOrArgChange: true,
    skip: !code || !window.location.pathname.includes(`/q/`) || !session?.id,
  });

  // // trigger a second request with resync flag on render
  // useEffect(() => {
  //   if (!resync && api.data) {
  //     setResync(true);
  //   }
  // }, [api.data]);

  // useEffect(() => {
  //   if (resync) {
  //     // Show indicator when fetching
  //     if (api.isFetching) {
  //       toast.loading(`Updating quote...`, { autoClose: false, toastId: `loading` });
  //     }

  //     if (!api.isFetching) {
  //       toast.dismiss(`loading`);
  //     }
  //   }
  // }, [api.isFetching, resync]);

  return api;
}
