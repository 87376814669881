import { Outlet } from "react-router";
import { ToastContainer } from "react-toastify";
import React from "react";

import { env } from "../../common/lib/env";

import { Footer } from "./Footer";
import { Auth0ProviderWithNav } from "./Auth/Auth0Provider";
import { Container } from "./Container";

const AuthParent = env.loginEnabled ? Auth0ProviderWithNav : React.Fragment;

export function Root() {
  return (
    <AuthParent>
      <Container>
        <Outlet />
      </Container>
      <Footer />
      <ToastContainer
        position={ `bottom-center` }
        autoClose={ 2000 }
        hideProgressBar={ false }
        newestOnTop={ true }
        closeOnClick
        draggable
        pauseOnHover
        theme={ `colored` }
      />
    </AuthParent>
  );
}
