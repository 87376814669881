import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useMemo, useState } from "react";
import { useParams } from "react-router";

import { useFetchContactQuery } from "../services/api/contact";
import { classNames } from "../../common/lib/classNames";
import { usePortalInvoice } from "../hooks/usePortalInvoice";
import { useSession } from "../hooks/useSession";

import { LoginButton } from "./Auth/Login";

interface ContainerProps {
  children: React.ReactNode;
}

const navigation = [];

export function Container({ children }: ContainerProps) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { code } = useParams();
  const session = useSession();

  const { data: contactResult } = useFetchContactQuery({ code, sessionId: session?.id }, {
    skip: !window.location.pathname.includes(`/c/`) || !session?.id,
  });

  const { data: invoiceResult } = usePortalInvoice();

  const logoUrl = useMemo(() => {
    return contactResult?.logoUrl || invoiceResult?.logoUrl || ``;
  }, [contactResult, invoiceResult]);

  const brandColor = useMemo(() => {
    return contactResult?.brandColor || invoiceResult?.brandColor || ``;
  }, [contactResult, invoiceResult]);

  return (
    <>
      <header className={ `absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10` }>
        <div className={ `mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8` }>
          <div className={ `flex flex-1 items-center gap-x-6` }>
            { /* <button type={ `button` }
              className={ `-m-3 p-3 md:hidden` }
              onClick={ () => setMobileMenuOpen(true) }>
              <span className={ `sr-only` }>{ `Open main menu` }</span>
              <Bars3Icon className={ `h-5 w-5 text-gray-900` }
                aria-hidden={ `true` } />
            </button> */ }
            <img
              className={ `h-12 w-auto` }
              src={ logoUrl ||  `/img/logo-color.png` }
              alt={ logoUrl ? `Company Logo` : `Paidnice` }
            />
          </div>
          <LoginButton />
          <nav className={ `hidden md:flex md:gap-x-11 md:text-sm md:font-semibold md:leading-6 md:text-gray-700` }>
            { navigation.map((item, itemIdx) => (
              <a key={ itemIdx }
                href={ item.href }>
                { item.name }
              </a>
            )) }
          </nav>
        </div>

        <Dialog as={ `div` }
          className={ `lg:hidden` }
          open={ mobileMenuOpen }
          onClose={ setMobileMenuOpen }>
          <div className={ `fixed inset-0 z-50` } />
          <DialogPanel className={ `fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10` }>
            <div className={ `-ml-0.5 flex h-16 items-center gap-x-6` }>
              <button type={ `button` }
                className={ `-m-2.5 p-2.5 text-gray-700` }
                onClick={ () => setMobileMenuOpen(false) }>
                <span className={ `sr-only` }>{ `Close menu` }</span>
                <XMarkIcon className={ `h-6 w-6` }
                  aria-hidden={ `true` } />
              </button>
              <div className={ `-ml-0.5` }>
                <a href={ `#` }
                  className={ `-m-1.5 block p-1.5` }>
                  <span className={ `sr-only` }>
                    { `Paidnice` }
                  </span>
                  <img
                    className={ `h-8 w-auto` }
                    src={ logoUrl ||  `/img/logo-color.png` }
                    alt={ logoUrl ? `Company Logo` : `Paidnice` }
                  />
                </a>
              </div>
            </div>
            <div className={ `mt-2 space-y-2` }>
              { navigation.map(item => (
                <a
                  key={ item.name }
                  href={ item.href }
                  className={ `-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50` }
                >
                  { item.name }
                </a>
              )) }
            </div>
          </DialogPanel>
        </Dialog>
      </header>
      <main>
        <header className={ `relative isolate pt-16` }>
          <div className={ `absolute inset-0 -z-10 overflow-hidden` }
            aria-hidden={ `true` }>
            <div className={
              classNames(
                `absolute left-16 top-full -mt-16  blur-3xl xl:left-1/2 xl:-ml-80`,
                brandColor ? `` : `transform-gpu opacity-50`,
              )
            }>
              <div
                className={ classNames(
                  `aspect-[1154/1154] w-[100.125rem]`,
                  brandColor ? `` : `bg-gradient-to-br from-lateGreen-400 to-lateGreen-900`, // When no brand color selected, default to ours
                ) }
                // Cant use tailwind for dynamic hex values, so do manually
                style={ {
                  backgroundColor: brandColor ? brandColor : undefined,
                } }
              />
            </div>
            <div className={ `absolute inset-x-0 bottom-0 h-px bg-gray-900/5` } />
          </div>
        </header>
        <div className={ `mt-6` }>
          { children }
        </div>
      </main>
    </>
  );
}
