import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { env } from '../common/lib/env';
import { createMiddleware } from '../common/middleware';

import { emptySplitApi as extendedApi } from './services/baseApi';

const rootReducer = combineReducers({
  [extendedApi.reducerPath]: extendedApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>

export const store = configureStore({
  reducer: rootReducer,
  middleware: createMiddleware,
  devTools: !env.isProd,
});
