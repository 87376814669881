import { IPortalQuoteResponse } from "shared";

import { emptySplitApi } from "../baseApi";
import { PortalCacheTags } from "../cacheTags";

export const quoteApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchQuote: builder.query<IPortalQuoteResponse, { code: string, sessionId: string }>({
      query: ({ code, sessionId }) => ({
        url: `quote/code/${code}`,
        method: `GET`,
        headers: {
          [`X-Portal-Session`]: sessionId,
        },
      }),
      providesTags: () => [PortalCacheTags.Quote],
    }),
    acceptQuote: builder.mutation<void, { code: string, sessionId: string }>({
      query: ({ code, sessionId }) => ({
        url: `quote/code/${code}/accept`,
        method: `POST`,
        headers: {
          [`X-Portal-Session`]: sessionId,
        },
      }),
      invalidatesTags: [PortalCacheTags.Quote],
    }),
    declineQuote: builder.mutation<void, { code: string, sessionId: string }>({
      query: ({ code, sessionId }) => ({
        url: `quote/code/${code}/decline`,
        method: `POST`,
        headers: {
          [`X-Portal-Session`]: sessionId,
        },
      }),
      invalidatesTags: [PortalCacheTags.Quote],
    }),
  }),
});

export const {
  useFetchQuoteQuery,
  useAcceptQuoteMutation,
  useDeclineQuoteMutation,
} = quoteApi;

