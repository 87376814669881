import { useRouteError } from 'react-router';
import { isAxiosError } from 'axios';

import { NotFound } from '../NotFound';

export function InvoiceError() {
  const error = useRouteError();

  if (isAxiosError(error)) {
    if (error.response?.status === 401) {
      // ...
    }
    else if (error.response?.status === 404) {
      // Dont give a clue that its real
      return <NotFound />;
    }

    return (
      <div id={ `error-page` }>
        <h1>{ `Oops! ` }{ error.response?.status }</h1>
        <p>{ error.response?.statusText }</p>
        { error.message && (
          <p>
            <i>{ error.message }</i>
          </p>
        ) }
      </div>
    );
  }
  else if (error instanceof Error) {
    return (
      <div id={ `error-page` }>
        <h1>{ `Oops! Unexpected Error` }</h1>
        <p>{ `Something went wrong.` }</p>
        <p>
          <i>{ error.message }</i>
        </p>
      </div>
    );
  }
  else {
    return <></>;
  }
}
