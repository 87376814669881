import { useParams } from "react-router";
import { useEffect, useState } from "react";

import { LoadingFallback } from "../LoadingFallback";
import { useFetchContactQuery } from "../../services/api/contact";
import { ErrorMessage } from "../Error";
import { mixpanelService } from "../../../common/lib/mixpanel";
import { useSession } from "../../hooks/useSession";

import { ContactPage } from "./ContactPage";

export function Contact() {
  const { code } = useParams();
  const session = useSession();
  const { data: contactResult, isLoading, error } = useFetchContactQuery({ code, sessionId: session?.id }, { skip: !code || !session?.id });
  const [pageViewed, setPageViewed] = useState(false);

  useEffect(() => {
    if (!pageViewed && contactResult && !isLoading && !error) {
      mixpanelService.portalPageLoad(`contact`);
      setPageViewed(true);
    }
  }, [pageViewed, contactResult, isLoading, error]);

  if (error) {
    return <ErrorMessage error={ error } />;
  }

  if (!contactResult && isLoading) {
    return <LoadingFallback />;
  }

  if (!contactResult) {
    return null;
  }

  return (
    <ContactPage
      contactDetails={ contactResult }
    />
  );
}
