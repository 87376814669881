import { SmallSpinner } from "../../common/Atoms/SmallSpinner";

export function LoadingFallback() {
  return (
    <div className={ `flex items-center justify-center h-screen` }>
      <SmallSpinner
        colored
        size={ `lg` }
      />
    </div>
  );
}
