import { Paragraph } from "../../common/Atoms/Typography/Paragraph";

export function Footer() {
  return (
    <footer>
      <div
        className={ `w-full flex justify-center items-center mt-12` }>
        <a
          href={ `https://www.paidnice.com?source=portal_powered` }
          target={ `_blank` }
          rel={ `noopener noreferrer` }
        >
          <Paragraph
            variant={ `link` }
          >
            {
              `Powered by Paidnice`
            }
          </Paragraph>
        </a>
      </div>
    </footer>
  );
}
