import { useAuth0 } from "@auth0/auth0-react";

import { Button } from "../../../common/Atoms/Button";
import { env } from "../../../common/lib/env";

export function LoginButton() {
  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();

  if (!env.loginEnabled) return null;

  if (isAuthenticated) {
    console.log(`auth0 user:`, user);

    return (
      <Button
        onClick={ () => logout({
          logoutParams: {
            returnTo: window.location.origin + `/logout`,
          },
        }) }
      >
        { `Logout` }
      </Button>
    );
  }

  const redirect = window.location.pathname === `/logout` ? `/` : window.location.pathname;

  return (
    <Button
      onClick={ () => loginWithRedirect({
        appState: {
          returnTo: redirect,
          loginSource: `portal`,
        },
      }) }
    >
      { `Customer Login` }
    </Button>
  );
}
