import { QuoteStatus } from "shared";

import { Button } from "../../../common/Atoms/Button";
import { Card } from "../../../common/Atoms/Card";
import { useDownload } from "../../../common/hooks/useDownload";
import { env } from "../../../common/lib/env";
import { usePortalQuote } from "../../hooks/usePortalQuote";
import { useAcceptQuoteMutation, useDeclineQuoteMutation } from "../../services/api/quote";
import { useSession } from "../../hooks/useSession";

export function QuoteActions() {
  const {
    data: response,
    refetch,
  } = usePortalQuote();
  const session = useSession();

  const [accept, { isLoading: acceptLoading }] = useAcceptQuoteMutation();
  const [decline, { isLoading: declineLoading }] = useDeclineQuoteMutation();

  const {
    download: downloadPdf,
    downloading: downloadingPdf,
  } = useDownload();

  function onDownloadPdf() {
    if (response?.quote) {
      const url = `${env.basePortalApiUrl}quote/code/${response.quote.hash}/download.pdf`;
      const filename = `quote_${response.contact.name}_${response.quote.number || ``}.pdf`;
      downloadPdf(url, filename);
    }
  }

  async function onAccept() {
    if (response?.quote && session?.id) {
      await accept({ code: response.quote.hash, sessionId: session.id });

      // For some reason, tags not working
      refetch();
    }
  }

  async function onDecline() {
    if (response?.quote && session?.id) {
      await decline({ code: response.quote.hash, sessionId: session.id });

      // For some reason, tags not working
      refetch();
    }
  }

  return (
    <Card className={ `flex justify-between mb-4` }>
      <div className={ `` }>
        <div className={ `space-x-2` }>
          <Button
            size={ `lg` }
            color={ `green` }
            disabled={ response?.quote?.status !== QuoteStatus.SENT || acceptLoading || declineLoading }
            loading={ acceptLoading }
            onClick={ onAccept }
          >
            { `Accept` }
          </Button>
          <Button
            color={ `red` }
            size={ `lg` }
            disabled={ response?.quote?.status !== QuoteStatus.SENT || declineLoading || acceptLoading }
            loading={ declineLoading }
            onClick={ onDecline }
          >
            { `Decline` }
          </Button>
        </div>
      </div>

      <div>
        <Button
          size={ `lg` }
          color={ `gray` }
          loading={ downloadingPdf }
          onClick={ onDownloadPdf }
          disabled={ downloadingPdf }
        >
          { `Download PDF` }
        </Button>
      </div>
    </Card>
  );
}
