import { useEffect, useState } from 'react';

import { LoadingFallback } from '../LoadingFallback';
import { ErrorMessage } from '../Error';
import { mixpanelService } from '../../../common/lib/mixpanel';
import { usePortalInvoice } from '../../hooks/usePortalInvoice';

import { InvoicePage } from './InvoicePage';

export function Invoice() {
  const { data: invoiceResult, isLoading, isFetching, error } = usePortalInvoice();
  const [pageViewed, setPageViewed] = useState(false);

  useEffect(() => {
    if (!pageViewed && invoiceResult && !isLoading && !error) {
      mixpanelService.portalPageLoad(`invoice`);
      setPageViewed(true);
    }
  }, [pageViewed, invoiceResult, isLoading, error]);

  if (error) {
    return <ErrorMessage error={ error } />;
  }

  if (!invoiceResult && isLoading) {
    return <LoadingFallback />;
  }

  if (!invoiceResult) {
    return null;
  }

  return (
    <InvoicePage invoiceData={ invoiceResult }
      isFetching={ isFetching }/>
  );
}
