import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { env } from '../../common/lib/env';

import { PortalCacheTags } from './cacheTags';

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  endpoints: () => ({}),
  reducerPath: `api`,
  tagTypes: Object.values(PortalCacheTags),
  baseQuery: fetchBaseQuery({
    baseUrl: env.basePortalApiUrl,
  }),
});
