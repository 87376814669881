import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useFetchInvoiceQuery } from "../services/api/invoice";

import { useSession } from "./useSession";

export function usePortalInvoice() {
  const session = useSession();

  const { code } = useParams<{ code: string}>();
  // On the second request we want to sync with accounting system
  const [resync, setResync] = useState(false);
  const api = useFetchInvoiceQuery({ code, resync, sessionId: session?.id }, {
    refetchOnMountOrArgChange: true,
    skip: !code || !window.location.pathname.includes(`/i/`) || !session?.id,
  });

  // trigger a second request with resync flag on render
  useEffect(() => {
    if (!resync && api.data) {
      setResync(true);
    }
  }, [api.data]);

  useEffect(() => {
    if (resync) {
      // Show indicator when fetching
      if (api.isFetching) {
        toast.loading(`Updating invoice...`, { autoClose: false, toastId: `loading` });
      }

      if (!api.isFetching) {
        toast.dismiss(`loading`);
      }
    }
  }, [api.isFetching, resync]);

  return api;
}
