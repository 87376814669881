import { env } from "../../../common/lib/env";

export function Profile() {
  if (!env.loginEnabled) return null;

  return (
    <div className={ `flex` }>
      { `Profile` }
    </div>
  );
}
