import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { When } from "react-if";

import { Card } from "../../../common/Atoms/Card";
import { useSession } from "../../hooks/useSession";
import { useFetchContactQuery } from "../../services/api/contact";
import { LoadingFallback } from "../LoadingFallback";
import { Paragraph } from "../../../common/Atoms/Typography/Paragraph";
import { DescriptionList } from "../../../common/Atoms/DescriptionList";

export function Quotes() {
  const { code } = useParams();
  const session = useSession();
  const { isLoading, data: contactResult } = useFetchContactQuery({ code, sessionId: session?.id }, { skip: !code || !session?.id });

  if (isLoading) {
    return <LoadingFallback />;
  }

  const content = contactResult?.quotes?.length > 0 ? (
    <div>
      <DescriptionList
        items={ contactResult.quotes.map(quote => ({
          title: quote.number,
          subText: quote.referenceText,
          child: (
            <div className={ `flex flex-col items-end` }>
              <Paragraph
                variant={ `link` }

              >
                <Link to={ `/q/${quote.hash}` }>
                  { `View Quote` }
                </Link>
              </Paragraph>

              <Paragraph
                variant={ `secondary` }
              >
                { quote.amountTotalFormatted }
              </Paragraph>

              <When condition={ quote.expiryDateString }>
                <Paragraph
                  variant={ `secondary` }
                >
                  { `Expires: ${quote.expiryDateString}` }
                </Paragraph>
              </When>
            </div>
          ),
        })) }
      />
    </div>
  ) : (
    <Paragraph
      variant={ `secondary` }
    >
      { `No current quotes found` }
    </Paragraph>
  );

  return (
    <Card>
      { content }
    </Card>
  );
}
