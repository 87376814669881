import { IPortalContactResponse } from "shared";

import { emptySplitApi } from "../baseApi";
import { PortalCacheTags } from "../cacheTags";

interface ContactClaimParams {
  code: string;
  accessToken: string;
}

export const contactApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchContact: builder.query<IPortalContactResponse, { code: string, sessionId: string}>({
      query: ({ code, sessionId }) => ({
        url: `contact/code/${code}`,
        method: `GET`,
        headers: {
          [`X-Portal-Session`]: sessionId,
        },
      }),
      providesTags: res => [{ type: PortalCacheTags.Contact, id: res?.statement?.contact?.id }],
    }),
    claimContact: builder.mutation<IPortalContactResponse, ContactClaimParams>({
      query: ({ code, accessToken }) => ({
        url: `contact/code/${code}/link`,
        method: `POST`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      invalidatesTags: [{ type: PortalCacheTags.Contact }],
    }),
  }),
});

export const {
  useClaimContactMutation,
  useFetchContactQuery,
} = contactApi;
