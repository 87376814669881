// https://stripe.com/docs/checkout/embedded/quickstart?client=react

// https://github.com/stripe/react-stripe-js/issues/14#issuecomment-1073300439 !!!!!!!!!!
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useMemo, useState } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import axios from 'axios';

import { env } from '../../../common/lib/env';
import { Modal } from '../../../common/Atoms/Modal';

interface IStripeCheckoutProps {
  code: string;
  connectedAccountId: string;
  onClose: () => void;
  mode: `invoice` | `statement`;
  invoiceIds?: string[];
}

export function StripeCheckout({ code, connectedAccountId, onClose, mode, invoiceIds }: IStripeCheckoutProps) {
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const stripePromise = useMemo(() => {
    // Cant load outside as we need the stripe account
    return loadStripe(env.stripePublicKey, {
      stripeAccount: connectedAccountId,
    });
  }, [connectedAccountId]);

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    axios(`${env.basePortalApiUrl}${mode}/pay/stripe/${code}`, {
      method: `POST`,
      data: invoiceIds ? { invoiceIds } : undefined,
    })
      .then(res => setClientSecret(res.data.clientSecret));
  }, []);

  const clientSecretMemo = useMemo(() => {
    return clientSecret;
  }, [clientSecret]);

  return (
    <div id={ `checkout` }>
      { (clientSecretMemo && stripePromise) && (
        <Modal
          open={ true }
          setOpen={ onClose }
          title={ `` }
          width={ `stripe` }
        >
          <EmbeddedCheckoutProvider
            stripe={ stripePromise }
            options={ { clientSecret: clientSecretMemo } }
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </Modal>
      ) }
    </div>
  );
}
