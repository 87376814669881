import { IPortalInvoiceResponse } from "shared";

import { emptySplitApi } from "../baseApi";
import { PortalCacheTags } from "../cacheTags";

export const invoiceApi = emptySplitApi.injectEndpoints({
  endpoints: builder => ({
    fetchInvoice: builder.query<IPortalInvoiceResponse, { code: string, resync?: boolean, sessionId: string }>({
      query: ({ code, resync, sessionId }) => ({
        url: `invoice/code/${code}`,
        method: `GET`,
        params: {
          resync: !!resync,
        },
        headers: {
          [`X-Portal-Session`]: sessionId,
        },
      }),
      providesTags: res => [{ type: PortalCacheTags.Invoice, id: res?.invoice?.id }],
    }),
  }),
});

export const {
  useFetchInvoiceQuery,
} = invoiceApi;

