import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router";

import { env } from "../../../common/lib/env";

export function Auth0ProviderWithNav({ children }) {
  const navigate = useNavigate();

  function onRedirectCallback(appState: AppState) {
    navigate(appState?.returnTo || window.location.pathname);
  }

  return (
    <Auth0Provider
      domain={ env.portalAuth0Domain }
      clientId={ env.portalAuth0ClientId }
      authorizationParams={ {
        redirect_uri: window.location.origin + `/callback`,
        loginSource: `portal`,
        audience: env.auth0BackendApiAudience,
      } }
      onRedirectCallback={ onRedirectCallback }
    >
      { children }
    </Auth0Provider>
  );
}
