import { useEffect } from 'react';
import ms from 'ms';
import { v4 } from "uuid";
import { useSessionStorage } from 'usehooks-ts';

// Controlling unique sessions for analytic reasons
const STORAGE_KEY = `pn-analytics-session`;

interface IAnalyticsSession {
  expires: number;
  id: string;
}

const SESSION_DURATION = ms(`1hour`);

function createSession(): IAnalyticsSession {
  const s = {
    expires: Date.now() + SESSION_DURATION,
    id: v4(),
  };

  return s;
}

export function useSession() {
  const [value, setValue, removeValue] = useSessionStorage<IAnalyticsSession>(STORAGE_KEY, null);

  useEffect(() => {
    if (!value) {
      setValue(createSession());
    }

    if (value?.expires < Date.now()) {
      removeValue();
      setValue(createSession());
    }
  }, [value]);

  return value;
}
